import React, { Component } from 'react'
import { noop, camelCase } from 'lodash'

const directions = {
  'x': ['left', 'right'],
  'y': ['up', 'down'],
}

const threshold = 10

export default class Swipable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startX: 0,
      startY: 0
    }
  }

  handleTouchStart = ({ targetTouches }) => {
    this.setState({
      startX: targetTouches[0].clientX,
      startY: targetTouches[0].clientY,
    })
  }

  handleTouchEnd = ({ changedTouches }) => {
    const { startX, startY } = this.state
    const { clientX, clientY } = changedTouches[0]
    this.handleSwipe(clientX - startX, clientY - startY)
  }

  handleSwipe = (deltaX, deltaY) => {
    const delta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY
    if (Math.abs(delta) > threshold) {
      const axis = Math.abs(deltaX) > Math.abs(deltaY) ? 'x' : 'y'
      const direction = directions[axis][delta < 0 ? 0 : 1]
      const callback = camelCase(`on-swipe-${ direction }`)
      return this.props[callback] ? this.props[callback]() : null
    }
  }

  render() {
    const { children, onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown, ...other } = this.props

    return (
      <div
        {...other}
        style={{
          'WebkitUserDrag': 'none',
          'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)',
        }}
        onTouchStart={ this.handleTouchStart }
        onTouchEnd={ this.handleTouchEnd }
      >
        {children}
      </div>
    )
  }
}
