import React, { Component, createRef } from 'react'
import { findDOMNode } from 'react-dom'
import { map, noop, isEqual } from 'lodash'
import { withViewportSize } from '../ViewportSize'
import Swipable from '../Swipable'
import cx from 'classnames'
import style from './carousel.module.scss'

class Carousel extends Component {
  static defaultProps = {
    onMove: noop,
  }

  state = {
    activeIndex: 0,
    itemWidth: 0,
  }

  firstItem = createRef()

  componentDidMount() {
    this.setItemWidth()
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { items, viewportWidth, viewportHeight, onMove } = this.props

    if (!isEqual(items, nextProps.items)) {
      this.setState({ activeIndex: 0 }, () => onMove(0))
    }

    if (viewportWidth !== nextProps.viewportWidth ||
        viewportHeight !== nextProps.viewportHeight) {
      this.setItemWidth()
    }
  }

  move = delta => {
    const { items, onMove } = this.props
    const { activeIndex } = this.state
    const nextActiveIndex = Math.max(0, Math.min(activeIndex + delta, items.length - 1))

    this.setState({ activeIndex: nextActiveIndex }, () => onMove(nextActiveIndex))
  }

  setItemWidth = () => {
    const itemWidth = this.firstItem.current ? findDOMNode(this.firstItem.current).getBoundingClientRect().width : 0
    this.setState({ itemWidth })
  }

  render() {
    const { className = '', items = [], renderItem } = this.props
    const { itemWidth, activeIndex } = this.state

    return (
      <Swipable
        aria-live="polite"
        className={ cx(style.main, className) }
        onSwipeLeft={ () => this.move(1) }
        onSwipeRight={ () => this.move(-1) }
      >
        <div
          className={ style.items }
          style={{
            transform: `translate3d(-${ itemWidth * activeIndex }px, 0, 0)`
          }}
        >
          {
            map(items, (item, index) =>
              <div
                ref={ index === 0 ? this.firstItem : undefined }
                className={ style.item }
                key={ index }
              >
              { renderItem(item, index) }
              </div>
            )
          }
        </div>
      </Swipable>
    )
  }
}

export default withViewportSize(Carousel)