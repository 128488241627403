import React from 'react'
import cx from 'classnames'
import withViewportAwareness from 'react-in-viewport'
import style from './enterviewport.module.scss'

const EnterTransition = withViewportAwareness(({
  innerRef, enterCount,
  className = style.willEnter,
  enterClassName = style.entered,
  delay = 0,
  children
}) => (
  <div
    ref={innerRef}
    className={cx({
      [enterClassName]: enterCount > 0,
    }, className)}
    style={ delay ? {
      transitionDelay: `${ delay }s`,
    } : undefined }
  >
    { children }
  </div>
))

export default EnterTransition

export const withEnterViewportTransition = Wrapped =>
  ({ enterClassName, className, delay, ...other }) => (
    <EnterTransition
      enterClassName={ enterClassName }
      className={ className }
    >
      <Wrapped {...other} />
    </EnterTransition>
  )