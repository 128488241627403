import React, { Component, createRef } from 'react'
import cx from 'classnames'
import { graphql } from 'gatsby'
import { map, noop } from 'lodash'
import Carousel from 'components/common/Carousel'
import Markdown from 'components/common/Markdown'
import EnterTransition from 'components/common/EnterViewportTransition'
import Card from './Card'
import Arrow from '~/assets/images/icon/arrow.svg'
import Chevron from '~/assets/images/icon/chevron.svg'
import style from './cards.module.scss'

export const query = graphql`
  fragment CardsFragment on ContentfulHomePage {
    cardsTitle
    cardGroups {
      title
      cards {
        type
        link
        title
        subtitle
        body {
          body
        }
        image {
          fluid {
            src
          }
        }
      }
    }
  }
`

const CarouselNavigationButton = ({ type, isDisabled, onClick }) =>
  <span
    className={ style[`${ type }Arrow`] }
    aria-label={ type }
    role="button"
    disabled={ isDisabled }
    onClick={ onClick }
  >
    <Chevron />
  </span>

export default class Cards extends Component {
  carousel = createRef()

  state = {
    selectedGroup: 0,
    activeCarouselIndex: 0,
  }

  setSelectedGroup = selectedGroup => this.setState({ selectedGroup })

  setActiveCarouselIndex = activeCarouselIndex => this.setState({ activeCarouselIndex })

  render() {
    const { activeCarouselIndex, selectedGroup } = this.state
    const { cardsTitle, cardGroups } = this.props.data
    const cards = cardGroups[selectedGroup].cards

    return (
      <div className={ style.main }>
        <div className={ style.links }>
          <Markdown component="h2" className={ style.title } content={ cardsTitle } />
          <ul className={ style.groupTitles }>
          {
            map(cardGroups, ({ title }, index) => (
              <li
                key={ index }
                className={ cx(style.groupTitle, {
                  [style.activeGroupTitle]: index === selectedGroup,
                }) }
                onClick={ () => this.setSelectedGroup(index) }
              >
                <EnterTransition delay={ index * 0.4 }>
                {
                  index === selectedGroup ?
                    <div className={ style.groupTitleIcon }>
                      <Arrow />
                    </div> :
                    null
                }
                { title }
                </EnterTransition>
              </li>
            ))
          }
          </ul>
          <div className={ style.navigation } aria-controls="cards">
            <CarouselNavigationButton
              type="previous"
              onClick={ () => this.carousel.current.move(-1) }
              isDisabled={ activeCarouselIndex <= 0 }
            />
            <CarouselNavigationButton
              type="next"
              onClick={ () => this.carousel.current.move(1) }
              isDisabled={ activeCarouselIndex + 1 >= cards.length }
            />
          </div>
        </div>
        <div className={ style.carouselContainer }>
            <Carousel
              id="cards"
              innerRef={ this.carousel }
              className={ style.carousel }
              onMove={ this.setActiveCarouselIndex }
              items={ cards }
              renderItem={ (item, index) =>
                <EnterTransition key={ `${ selectedGroup }-${ index }` } delay={ index * 0.25 }>
                  <Card { ...item } />
                </EnterTransition>
              }
            />
        </div>
      </div>
    )
  }
}
