import React from 'react'
import { flowRight, map } from 'lodash'
import { Link, graphql } from 'gatsby'
import { Trans } from '@lingui/macro'
import routes from '~/routes'
import { withLocale } from 'components/common/Locale'
import { withAuth } from 'components/common/Auth'
import EnterTransition from 'components/common/EnterViewportTransition'
import Markdown from 'components/common/Markdown'
import Arrow from '~/assets/images/icon/arrow.svg'
import style from './hero.module.scss'

const redirect = (slug, isLoggedIn, locale) => ({
  'public' : routes.visibilityGroup({ slug: 'public', node_locale: locale }),
  'private': routes.visibilityGroup({ slug: 'private', node_locale: locale }),
  'chat': isLoggedIn ? routes.chat() : routes.register(),
  'professional': isLoggedIn ? routes.visibilityGroup({ slug: 'professional', node_locale: locale }) : routes.register('professional'),
})[slug] || ''

export const query = graphql`
  fragment HeroFragment on ContentfulHomePage {
    heroTitle
    heroBackgroundImage {
      fluid {
        src
      }
    }
    heroSubtitle
    hero {
      title
      subtitle
      link
    }
  }
`

const Hero = ({ i18n, data, isLoggedIn, locale }) => (
  <div className={ style.main } style={{
    backgroundImage: `url(${ data.heroBackgroundImage.fluid.src })`,
  }}>
    <div className={ style.primaryContent }>
      <div className={ style.container }>
        <EnterTransition delay={ 0.5 }>
          <h1 className={ style.title }>
            <Markdown content={ data.heroTitle } />
            <Link
              className={ style.aboutLink }
              to={
                routes.institutional({
                  node_locale: locale,
                  key: 'about',
                })
              }>
              <Markdown content={ data.heroSubtitle } />
            </Link>
          </h1>
        </EnterTransition>
        <EnterTransition delay={ 0.6 }>
          <div className={ style.foundation }>
            <Trans>Una iniciativa de:</Trans>
            <a href="https://fundacionstepbystep.com" target="_blank" rel="noopener noreferrer">
              <img
                className={ style.logo }
                src={ require('~/assets/images/step-by-step-foundation.png') }
              />
            </a>
          </div>
        </EnterTransition>
      </div>
    </div>
    <div className={ style.secondaryContent }>
      <div className={ style.container }>
      {
        map(data.hero, ({ title, subtitle, link }, index) =>
          <EnterTransition key={ title } delay={ index * 0.25 + 1 }>
            <div key={ title } className={ style.linkGroup }>
              <h3 className={ style.linkGroupTitle }>{ title }</h3>
              <Link className={ style.linkGroupLink } to={ redirect(link, isLoggedIn, locale) }>
                { subtitle }
                <Arrow />
              </Link>
            </div>
          </EnterTransition>
        )
      }
      </div>
    </div>
  </div>
)

export default flowRight(
  withAuth,
  withLocale
)(Hero)
