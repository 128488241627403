import React, { Component } from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { debounce } from 'lodash'

export const withViewportSize = Wrapped => {
  class WithViewportSize extends Component {
    state = {
      width: 0,
      height: 0,
    }

    componentDidMount() {
      this.resizeHandler = debounce(this.handleResize.bind(this))
      window.addEventListener('resize', this.resizeHandler)
      this.handleResize()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resizeHandler)
    }

    handleResize = () => {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      this.setState({ width, height })
    }

    render() {
      const { innerRef, ...props } = this.props
      return (
        <Wrapped
          {...props}
          ref={ innerRef }
          viewportWidth={this.state.width}
          viewportHeight={this.state.height}
        />
      )
    }
  }

  hoistNonReactStatics(WithViewportSize, Wrapped)

  return WithViewportSize
}