import React from 'react'
import cx from 'classnames'
import style from './circle.module.scss'

let progress = 0

export default ({ className, isBlue, type, src, alt}) => (
  <div className={ cx(style.main, {
    [style.isEmpty]: !src,
    [style.isBlue]: isBlue,
  }, className) }>
    <div className={ style.content }>
      {
        src ?
          (
            type === 'video' ?
              <video className={ style.video }>
                <source type={ type } src={ src } />
              </video> :
              <img className={ style.image } src={ src } alt={ alt } />
          ) :
          <div className={ style.white } />
      }
    </div>
  </div>
)