import React from 'react'
import { Link, graphql } from 'gatsby'
import { Trans } from '@lingui/macro'
import routes from '~/routes'
import { withLocale } from 'components/common/Locale'
import EnterTransition from 'components/common/EnterViewportTransition'
import Button from 'components/common/Button'
import Markdown from 'components/common/Markdown'
import style from './prefooter.module.scss'

export const query = graphql`
  fragment PrefooterFragment on ContentfulHomePage {
    prefooterTitle
    prefooterCta
    prefooterLogo {
      fluid {
        src
      }
    }
    prefooterFoundationLogo {
      fluid {
        src
      }
    }
    prefooterBackground {
      fluid {
        src
      }
    }
  }
`

const Prefooter = ({ data, locale }) => (
  <div className={ style.main } style={{
    'backgroundImage': `url(${ data.prefooterBackground.fluid.src })`,
  }}>
    <div className={ style.container }>
      <div className={ style.copy }>
        <EnterTransition delay={ 0.25 }>
          <Markdown component="h2" className={ style.title } content={ data.prefooterTitle } />
        </EnterTransition>
        <EnterTransition delay={ 0.5 }>
          <Button
            component={ Link }
            to={
              routes.institutional({
                node_locale: locale,
                key: 'about',
              })
            }
            isSecondary
          >
            { data.prefooterCta }
          </Button>
        </EnterTransition>
      </div>
      <div className={ style.image }>
        <EnterTransition>
            <img
              className={ style.logo }
              src={ data.prefooterLogo.fluid.src }
            />
        </EnterTransition>
        <EnterTransition delay={ 0.25 }>
          <div className={ style.foundation }>
            <Trans>Una iniciativa de:</Trans>
            <a href="https://fundacionstepbystep.com" target="_blank" rel="noopener noreferrer">
              <img
                className={ style.foundationLogo }
                src={ data.prefooterFoundationLogo.fluid.src }
              />
            </a>
          </div>
        </EnterTransition>
      </div>
    </div>
  </div>
)

export default withLocale(Prefooter)