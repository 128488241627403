import React from 'react'
import cx from 'classnames'
import { graphql } from 'gatsby'
import Markdown from 'components/common/Markdown'
import style from './joinchat.module.scss'
import EnterViewport from 'components/common/EnterViewportTransition'

export const query = graphql`
  fragment JoinChatFragment on ContentfulHomePage {
    chatTitle
    chatSubtitle
    chatImage {
      fluid {
        src
      }
    }
  }
`

export default ({ className, data }) => (
  <div className={ cx(style.main, className) }>
    <div className={ style.copy }>
      <EnterViewport>
        <Markdown component="h2" className={ style.title } content={ data.chatTitle } />
        <Markdown component="div" className={ style.subtitle } content={ data.chatSubtitle } />
      </EnterViewport>
    </div>
    <div className={ style.imageContainer }>
      <EnterViewport delay={ 0.4 }>
        <img className={ style.image } src={ data.chatImage.fluid.src } alt={ data.chatTitle } />
      </EnterViewport>
    </div>
  </div>
)