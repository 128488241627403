import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { withLocalizedQueryData } from 'components/common/Locale'
import { withAuth } from 'components/common/Auth'
import { Default as Layout } from 'components/layout/Layout'
import {
  Hero,
  Circles,
  Cards,
  JoinChat,
  Prefooter,
} from 'components/section/Home'

const Home = ({ data, location }) =>
  <Layout isHomeLayout location={ location }>
    <Hero data={ data } />
    <Circles data={ data } />
    <Cards data={ data } />
    <JoinChat data={ data } />
    <Prefooter data={ data } />
  </Layout>

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHomePage {
      edges {
        node {
          node_locale
          ...HeroFragment
          ...CirclesFragment
          ...CardsFragment
          ...JoinChatFragment
          ...PrefooterFragment
        }
      }
    }
  }
`
export default withLocalizedQueryData(Home)
