import React from 'react'
import { Link, graphql } from 'gatsby'
import cx from 'classnames'
import { map, take } from 'lodash'
import Circle from './Circle'
import Markdown from 'components/common/Markdown'
import EnterTransition from 'components/common/EnterViewportTransition'
import style from './circles.module.scss'

const empty = {}

export const query = graphql`
  fragment CirclesFragment on ContentfulHomePage {
    circlesTitle
    circlesSubtitle
    circlesImages {
      fluid {
        src
      }
    }
  }
`

export default ({ data }) => (
  <div className={ style.main }>
    <div className={ style.container }>
      <EnterTransition>
        <Markdown component="h2" className={ style.title } content={ data.circlesTitle } />
      </EnterTransition>
      <EnterTransition delay={ 0.5 }>
        <div className={ style.subtitle }>
          { data.circlesSubtitle }
        </div>
      </EnterTransition>
    </div>
    <div className={ style.circles }>
      {
        map([
          ...take(map(data.circlesImages, 'fluid'), 6),
          empty,
          empty,
          empty,
        ], (props, index) =>
          <Circle
            key={ index }
            className={ style.circle }
            isBlue={ index % 2 === 1 }
            { ...props }
          />
        )
      }
    </div>
  </div>
)
