import React, { createElement } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import Button  from 'components/common/Button'
import BookmarkIcon from '~/assets/images/icon/bookmark.svg'
import Markdown  from 'components/common/Markdown'
import style from './card.module.scss'

const LinkType = ({ title, body, link }) =>
  <a className={ cx(style.main, style.linkMain) } href={ link }>
    <div>
      <Markdown component="span" className={ style.linkTitle } content={ title } />
      <Markdown component="span" className={ style.linkBody } content={ body.body } />
    </div>
    <div className={ style.linkIcon }>
      <BookmarkIcon />
    </div>
  </a>

const TestimonialType = ({ body, title: name, subtitle: location, image: avatar }) =>
  <div className={ cx(style.main, style.testimonialMain) }>
    <span className={ style.testimonialBody }>
      { body.body  }
    </span>
    <div className={ style.testimonialUser }>
      <img className={ style.testimonialAvatar } src={ avatar.fluid.src } alt={ name } />
      <div className={ style.testimonialName }>
        { name }
      </div>
      <div className={ style.testimonialLocation }>
        { location }
      </div>
    </div>
  </div>

const FullImageType = ({ title, link, image }) =>
  <div
    className={ cx(style.main, style.fullImageMain) }
    style={{
      backgroundImage: `url(${ image.fluid.src })`,
    }}
  >
    <Button isPrimary component={ Link } to={ link } className={ style.fullImageButton }>
      { title }
    </Button>
  </div>

const SplitImageType = ({ title = '', image }) =>
  <div className={ cx(style.main, style.splitImageMain) }>
    <Markdown component="div" className={ style.copy } content={ title } />
    <img className={ style.image } src={ image.fluid.src } />
  </div>

const types = {
  'link': LinkType,
  'testimonial': TestimonialType,
  'fullimage': FullImageType,
  'splitimage': SplitImageType,
}

export default ({ type, ...props }) => createElement(types[type], props)
